<template>

  <div class="container-fluid p-0 p-md-3">
    <div class="row row-cols-1 row-cols-md-4 row-cols-lg-6 g-0 g-md-4 row-eq-height justify-content-start">
      <template v-for="exhibitor in exhibitors" :key="exhibitor.id">
        <exhibitor-item :exhibitor="exhibitor" v-if="exhibitor"></exhibitor-item>
      </template>
    </div>
  </div>

</template>
<script>

import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";
import {alertController} from "@ionic/vue";
import {mapActions} from "vuex";
import ExhibitorItem from "@/components/ExhibitorItem";

addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {ExhibitorItem},
  props: ['exhibitors'],
  created() {
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  }

};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
