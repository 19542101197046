<template>
  <div class="container-fluid p-0 p-md-3">
    <div
        class="row row-cols-1 row-cols-md-3  row-cols-lg-4 row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start">
      <template v-for="session in sessions" :key="session.id">
        <session-item :session="session"></session-item>
      </template>

    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {mapActions} from "vuex";
import SessionItem from "@/components/SessionItem";
//moment.locale("de");
export default {
  data() {
    return {}
  },
  components: {
    'session-item': SessionItem
  },
  props: ['sessions'],
  created() {
    this.moment = moment;
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),
  }

};
</script>

