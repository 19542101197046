<template>
  <div class="col " v-if="!exhibitor.isLetter">
    <div class="card pt-2 pb-2 pe-2 ps-2 h-100 exhibitor-item">
    <a @click="showDisclaimer(exhibitor)" class="item item-icon-right">
        
        
        <div class="row">
          <div class="py-0 " >
            <div style="text-align: center; font-weight: bold" class="card-title col mt-2 mb-0 mt-md-2 mb-md-0">{{ exhibitor.short_name || exhibitor.name }}</div>
          </div>
        </div>
        <div v-if="exhibitor.logo" class="row  mt-2 align-items-center justify-content-center align-content-center">
          <img class="img-fluid rounded-start  p-2" v-if="exhibitor.logo" style="height: 90px;object-fit: contain"
               :src="'https://fileserver-188ab.kxcdn.com/file/' +exhibitor.logo.split('/')[4]">
          <img class="img-fluid rounded-start" v-if="!exhibitor.logo" src="" style="height: 90px; object-fit: contain">
        </div>
            <div style="text-align: center;" class="card-text pb-0 pb-md-2 mt-1  mb-0 locations" v-if="exhibitor.locationList && exhibitor.locationList.length>0 ">
               {{ wordings[currentLanguage].BOOTHS }}:       
              </div>
              <div style="text-align: center" >
                <span style="margin-right:10px;" v-for="location in exhibitor.locationList" :key="location.id">  {{ location.name }}    </span>
              </div> 
      </a>
    </div>
  </div>
  <div class="col-12 divider" v-if="exhibitor.isLetter">
    <h2>{{ exhibitor.letter }}</h2>
  </div>
</template>
<script>

import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";
import {alertController} from "@ionic/vue";
import {mapActions, mapState} from "vuex";

addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {},
  props: ['exhibitor'],
  created() {
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),

  },

};
</script>
<style lang="scss">
a {
  text-decoration: none;
}

.divider {
  width: 100vw;
  padding-left: 20px
}

.exhibitor-item {
  font-size: 0.9rem;
  border-radius: 20px;
  position: relative !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  .locations {
    font-size: 0.9rem;

    span:not(:last-child)::after {
      content: ", ";
    }
  }
}

@media(max-width: 767px) {
  .exhibitor-item {
    border-radius: 0;
    //border-bottom: 1px solid lightgrey;
    box-shadow: none !important;

  }
}
</style>
